<template>
    <div class="audio-wavesurfer" style="height:35px;cursor:pointer!important" ref="WaveSurfer">
        <!-- 波形图 -->
    </div>
</template>

<script>
import WaveSurfer from 'wavesurfer.js'
// import CursorPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.cursor.js'
import {debounce as _debounce} from '@/utils/underscore'
export default {
    name: 'WaveSurfer',
    props:{
        audioUrl: {
            type: String,
            required: true
        },
        waveData: {
            type: null,
            default: []
        }
    },
    data(){
        return {
            wavesurfer: null,
            initPlaying: false,
            playing: false
        }
    },
    watch: {
        audioUrl: {
            handler(val) {
                if (val && val !== '') {
                    this.initAudioUrl();
                    // this.initPlaying = old && old !== ''
                }
            },
            immediate: true
        }
    },
    created(){
        this.$nextTick(() => {
            this.init()
        })
    },
    beforeDestroy() {
        this.destroyWavesurfer()
    },
    methods:{
        init(){
            const vm = this
            const $waveSurfer = this.$refs.WaveSurfer;
            const ctx = document.createElement('canvas').getContext('2d');
            const linGrad = ctx.createLinearGradient(0, 64, 0, 200);
            linGrad.addColorStop(1, 'rgba(224, 224, 224, 1.000)');
            linGrad.addColorStop(1, 'rgba(224, 224, 224, 1.000)');
            // 初始化播放器内容
            vm.$nextTick(()=>{
                vm.wavesurfer = WaveSurfer.create({
                    container: $waveSurfer, // 容器
                    waveColor: linGrad, // 光标后波形的填充颜色
                    progressColor: '#E52A0D', // 光标后面波形部分的填充颜色。当progressColor和waveColor相同时，根本不会渲染进度波
                    backend: 'MediaElement',
                    cursorColor: '#fff', // 指示播放头位置的光标填充颜色。
                    barGap: 2, // 波浪条之间的可选间距（如果未提供）将以旧格式计算。
                    height: 35,  // 波形的高度
                    barWidth: 1,
                    muted: false, // 是否静音
                    barMinHeight: 0.1, // 波形条的高度。大于 1 的数字将增加波形条的高度
                    scrollParent: false, // 是否以长波形滚动容器。否则波形会缩小到容器宽度（参见fillParent）。
                    normalize: false,
                    // maxCanvasWidth: 50000,
                    minPxPerSec: 1, // 每秒音频的最小像素数。
                    // audioRate: '1', // 播放音频的速度
                    // partialRender: true, // 提高大波形的渲染速度
                    backgroundColor: 'transparent',
                    // plugins: [
                    //     // 光标插件
                    //     CursorPlugin.create({
                    //         showTime: false,
                    //         opacity: 1,
                    //         customShowTimeStyle: {
                    //             'background-color': '#000',
                    //             color: '#fff',
                    //             padding: '2px',
                    //             'font-size': '13px'
                    //         }
                    //     })
                    // ]
                });
                vm.wavesurfer.setVolume(1) // 初始化时设置播放音量  默认值
                vm.wavesurfer.on('ready', function () {
                    // console.log('加载');
                    vm.$emit('ready', vm.initPlaying)
                });
                vm.wavesurfer.on('pause', _debounce(function () {
                    vm.$emit('pause')
                }, 200));
                vm.wavesurfer.on('play', _debounce(function () {
                    // console.log('播放')
                    vm.playing = true;
                    vm.$emit('play')
                }, 200));
                vm.wavesurfer.on('error', function () {
                    vm.playing = false
                    vm.$emit('pause')
                });
                vm.wavesurfer.on('finish', function () {
                    vm.playing = false
                    vm.$emit('finish')
                });
                vm.wavesurfer.on('loading', function () {
                    // console.log('波形图加载');
                    // if (vm.waveData) {
                    //     // 取消音频文件加载过程。
                    //     vm.wavesurfer.cancelAjax()
                    // }
                });
                vm.wavesurfer.on('seek', function (e) {
                    vm.$emit('seek', e)
                });
                // console.log(vm.audioUrl,'音频文件')
                if (vm.audioUrl && vm.audioUrl !== '') {
                    vm.$nextTick(() => {
                        vm.initAudioUrl()
                    })
                }
            })
        },
        /**
         * 初始化音乐文件
         */
        initAudioUrl: _debounce(function () {
            this.initAudiowave()
        }, 10),
        initAudiowave() {
            const {wavesurfer, audioUrl, waveData} = this
            if (wavesurfer) {
                wavesurfer.empty() // 清除波形图
                // console.log(audioUrl,'音频地址', waveData, '波形数据');
                this.$nextTick(() => {
                    wavesurfer.load(audioUrl, waveData && waveData.length > 0 ? waveData : undefined, 'auto');
                })
            }
        },
        /**
         * 销毁示例及监听
         */
        destroyWavesurfer() {
            this.wavesurfer.cancelAjax()
            this.wavesurfer.unAll()
            this.wavesurfer.destroy()
        },
        /**
         * 播放开始
        */
        play() {
            // console.log('播放波形图吧');
            this.wavesurfer && this.wavesurfer.play()
        },
        /**
         * 播放暂停
         */
        pause() {
            this.playing = false;
            this.wavesurfer && this.wavesurfer.pause()
        },
        /**
         * 是否处于播放状态
         */
        isPlay() {
            return this.wavesurfer && this.wavesurfer.isPlaying()
        },
        /**
         * 获取当前音乐的进度
         */
        getCurrentTime() {
            return this.wavesurfer && this.wavesurfer.getCurrentTime()
        },
        /**
         * 获取音量
        */
        getVolume() {
            return this.wavesurfer && this.wavesurfer.getVolume()
        },
        /**
         * 设置音乐音量
         */
        setVolume(val) {
            this.wavesurfer && this.wavesurfer && this.wavesurfer.setVolume(val)
        },
        /**
         * 静音当前声音。可以是true静音或false取消静音的布尔值
         */
        setMute(val) {
            this.wavesurfer && this.wavesurfer.setMute(val)
        },
        /**
         * 暂停时播放，播放时暂停。
         */
        playPause() {
            this.wavesurfer && this.wavesurfer.playPause()
        }
    }
}
</script>

<style lang="scss" scoped>
.audio-wavesurfer{
    width: 100%;
}
/deep/wave{
    cursor: pointer!important;
}
</style>
