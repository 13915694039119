<template>
  <div class="identify-container" v-if="!isOver">
    <el-upload
      :multiple="false"
      :show-file-list="false"
      ref="upload"
      :on-success="handleSuccess"
      drag
      :limit="1"
      :before-upload="beforeUpload"
      action="https://upload-z1.qiniup.com"
      :accept="accept">
      <slot>
        <i class="el-icon-upload" />
        <div class="el-upload__text">选择音频/视频文件或将文件拖至此处开始识别</div>
      </slot>
    </el-upload>
    <div class="el-upload__tip" slot="tip">支持MP3、MP4、WAV、AVI、FLV、WMV、MOV，音频文件不超过100M，视频文件不超过200M</div>
  </div>
  <div class="identify-container" v-else>
    <Player></Player>
  </div>
</template>
<script>
import Player from "./components/player"
export default {
  components:{Player},
  data() {
    return {
      accept:".mp3,.mp4,.wav,.avi,.flv,.wmv,.mov",
      isOver: true
    }
  },
  methods: {
    // 上传成功
    handleSuccess(e) {
      const { key } = e || {};
      console.log(key)
    },
    // 上传前验证
    beforeUpload(file) {
      console.log(file)
    },
    // 上传进度展示
  }
}
</script>
<style lang="scss" scoped>
.identify-container{
  width: 100%;
  padding-top: 70px;
  padding-bottom: 80px;
  min-height: calc(100vh - 268px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
//.upload-container {
//  width: 100%;
//  position: relative;
//  /deep/ {
//    .el-upload-dragger {
//      width: 168px;
//      height: 106px;
//      background: #f5f6fa;
//      border-radius: 5px;
//      font-size: 12px;
//      .el-icon-upload {
//        font-size: 20px;
//        line-height: 12px;
//        margin-top: 31px;
//        margin-bottom: 15px;
//      }
//      .el-upload__text {
//        font-size: 12px;
//        color: rgba(51, 51, 48, 0.5);
//      }
//    }
//  }
//  .image-preview {
//    width: 168px;
//    height: 106px;
//    position: relative;
//    border: 1px dashed #d9d9d9;
//    .image-preview-wrapper {
//      position: relative;
//      width: 100%;
//      height: 100%;
//      img {
//        width: 100%;
//        height: 100%;
//      }
//    }
//    .image-preview-action {
//      position: absolute;
//      width: 100%;
//      height: 100%;
//      left: 0;
//      top: 0;
//      cursor: default;
//      text-align: center;
//      color: #fff;
//      opacity: 0;
//      font-size: 20px;
//      background-color: rgba(0, 0, 0, 0.5);
//      transition: opacity 0.3s;
//      cursor: pointer;
//      text-align: center;
//      line-height: 106px;
//      .el-icon-delete {
//        font-size: 36px;
//      }
//    }
//    &:hover {
//      .image-preview-action {
//        opacity: 1;
//      }
//    }
//  }
//}
</style>
