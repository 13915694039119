<template>
  <div class="player">
    <div class="player_wave">
      <div class="playandpause"></div>
      <div class="wavebox">
        <WaveSurfer
          ref="WaveSurferAudio"
          :audio-url="waveAudioUrl"
          :wave-data="waveData"
          @ready="onReady"
          @error="onError"
          @finish="onEnded"
          @seek="handleSeek" />
      </div>
      <div class="wavetime">00:10/02:30</div>
    </div>
    <div class="playerList">
      <el-table :data="[]">
        <el-table-column label="歌曲名称" prop=""></el-table-column>
        <el-table-column label="时长" prop=""></el-table-column>
        <el-table-column label="操作" width="200" align="center">
          <tamplate slot-scope="scope">
            <el-button type="text" @click="getlikemusic(scope.row)">相似音乐</el-button>
            <el-button type="text">获取版权</el-button>
          </tamplate>
        </el-table-column>
      </el-table>
    </div>
    <el-button>重新匹配</el-button>
  </div>
</template>
<script>
import WaveSurfer from '@/components/VfAudio/components/WaveSurfer'
export default {
  components:{WaveSurfer},
  data(){
    return{
      waveAudioUrl:'https://qkodo.playlistmusic.com.cn/watermark/6f00ec7bee164098001/13af2120160f65183ac676fcfca167a4.wav',
      waveData: []
    }
  },
  methods:{
    onReady(){

    },
    onError(){

    },
    onEnded(){

    },
    handleSeek(){

    },
    getlikemusic(item){
      console.log(item)
    }
  }
}
</script>
<style scoped lang="scss">
.player{
  width: 100%;
  height: 100%;
  .player_wave{
    width: 100%;
    display: flex;
    align-items: center;
    .wavebox{
      flex: 1;
      margin: 0 15px;
    }
  }
  .playerList{
    width: 100%;
    margin-top: 40px;
  }
}
</style>
